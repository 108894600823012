import "./styles.css";
import mediumZoom from 'medium-zoom';

// mediumZoom(['img']);
// mediumZoom('[img]')
// mediumZoom('[data-zoomable]')


// mediumZoom(document.querySelectorAll('img'));


document.getElementById("app").innerHTML = `
<!DOCTYPE html>
<html>

<head>
    <title>Jerzy Pinkas - portfolio</title>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="stylesheet" href="style.css" type="text/css" />
</head>

<body>
<!-- section 1 -->
    <div class="container">
        <div class="outer">
            <div class="borderTLH"></div>
            <div class="borderTLV"></div>

            <div class="borderTRH"></div>
            <div class="borderTRV"></div>

            <div class="borderBRH"></div>
            <div class="borderBRV"></div>

            <div class="borderBLH"></div>
            <div class="borderBLV"></div>

            <div class="inner">
                <div class="item right bottom">
                    <img src="image/static/jerzy-pinkas.png" class="img data-zoomable" alt="Jerzy Pinkas" title="Jerzy Pinkas">
                </div>
                <div class="hr-vertical hr-vertical-top"></div>
                <div class="item center bottom">
                    <div>
                        <h1 class="font-header">Jerzy Pinkas</h1>
                        <h2 class="font-header">fotograf</h2>
                    </div>
                </div>
                <div class="hr-vertical hr-vertical-top"></div>
                <div class="item left bottom">
                    <p class="text text-left lg">Patrzę na miejsca z powietrza, podziwiam, a potem pokazuje Tobie… Unikalność tej perspektywy to niezwykłe detale krajobrazów, architektury i miejskich przestrzeni, odsłaniające ukryte wzory i harmonie… </p>
                    <p class="text text-left sm">Patrzę na miejsca z powietrza, podziwiam, a potem pokazuje Tobie… </p>
                </div>

                <div class="column-3 hr-horizontal"></div>

                <div class="item center middle">
                    <div>
                        <p class="font-medium text-center">www.pinkas.pl</p>
                        <p class="font-medium text-center">jerzy@pinkas.pl</p>
                        <p class="font-medium text-center">tel. 504 840 613</p>
                    </div>
                </div>
                <div class="hr-vertical  hr-vertical-middle"></div>
                <div class="item column-2 center middle">
                    <h3 class="sm">PORT<br>FOLIO</h3>
                    <h3 class="lg">PORTFOLIO</h3>
                </div>

                <div class="column-3 hr-horizontal"></div>

                <div class="item right top">
                    <p class="text text-right lg">
                        W moim portfolio znajdziesz zdjęcia nagradzane w konkursach takich jak Gdansk Press Photo 2023 oraz "Stocznia. Drugie życie". Poniżej zobaczysz miejsca zwykłe i niezwykłe, ale także…
                    </p>
                    <p class="text text-right sm">
                        W moim portfolio znajdziesz zdjęcia nagradzane Gdansk Press Photo 2023 oraz "Stocznia. Drugie życie".
                    </p>
                </div>
                <div class="hr-vertical hr-vertical-bottom"></div>
                <div class="item center top">
                    <ul class="icons">
                        <li><img src="image/static/adobe-icons/ps.svg" class="icon" alt="Adobe PhotoShop" title="Adobe PhotoShop" /></li>
                        <li><img src="image/static/adobe-icons/lr.svg" class="icon" alt="Adobe Lightroom" title="Adobe Lightroom" /></li>
                        <li><img src="image/static/adobe-icons/ai.svg" class="icon" alt="Adobe Illustrator" title="Adobe Illustrator" /></li>
                        <li><img src="image/static/adobe-icons/xd.svg" class="icon" alt="Adobe XD" title="Adobe XD" /></li>
                        <li><img src="image/static/adobe-icons/ae.svg" class="icon" alt="Adobe After Effects" title="After Effects" /></li>
                    </ul>
                </div>
                <div class="hr-vertical hr-vertical-bottom"></div>
                <div class="item left top">
                    <img src="image/static/jp-praca-1.jpg" class="img" alt="Jerzy Pinkas" title="Jerzy Pinkas">
                </div>
            </div>
        </div>
    </div>

<!-- section 2 -->
<div class="container">
    <div class="outer">
        <div class="borderTLH"></div>
        <div class="borderTLV"></div>

        <div class="borderTRH"></div>
        <div class="borderTRV"></div>

        <div class="borderBRH"></div>
        <div class="borderBRV"></div>

        <div class="borderBLH"></div>
        <div class="borderBLV"></div>

        <div class="inner">
            <div class="item right bottom">
                <img src="image/koga/01.jpg" class="img" alt="Koga 01" title="Biurowiec Koga 01">
            </div>
            <div class="hr-vertical hr-vertical-top-small"></div>
            <div class="item center bottom">
                <img src="image/koga/02.jpg" class="img" alt="Koga 01" title="Biurowiec Koga 02">
            </div>
            <div class="hr-vertical hr-vertical-top-small"></div>
            <div class="item left bottom">
                <img src="image/koga/03.jpg" class="img" alt="Koga 03" title="Biurowiec Koga 03">
            </div>

            <div class="column-3 hr-horizontal"></div>

            <div class="item column-2 center middle">
                <div>
                    <p class="font-large text-center">Gdansk Press Photo 2023</p>
                    <p class="font-medium  text-center">KogaDach - nagroda w kategorii "Życie i świat wokół nas"</p>
                </div>
            </div>
            <div class="hr-vertical hr-vertical-middle"></div>
            <div class="item left middle">
                <img src="image/koga/04.jpg" class="img" alt="Koga 04" title="Biurowiec Koga 04">
            </div>

            <div class="column-3 hr-horizontal"></div>

            <div class="item right top">
                <p class="text text-right">Każdy biurowiec to nuda? Każdy wygląda tak samo? To nie jest prawda! Trzeba szukać i patrzeć! Szukać detali, odkrywać, eksplorować i przede wszystkim myśleć obrazem… Ten nagrodzony zestaw fotografii odkrywa zwykły dach…</p>
            </div>
            <div class="hr-vertical hr-vertical-bottom-small"></div>
            <div class="item center top">
                <img src="image/koga/06.jpg" class="img" alt="Koga 06" title="Biurowiec Koga 06">
            </div>
            <div class="hr-vertical hr-vertical-bottom-small"></div>
            <div class="item left top">
                <img src="image/koga/05.jpg" class="img" alt="Koga 05" title="Biurowiec Koga 05">
            </div>
        </div>
    </div>
</div>

<!-- section 3 -->
<div class="container">
    <div class="outer">
        <div class="borderTLH"></div>
        <div class="borderTLV"></div>

        <div class="borderTRH"></div>
        <div class="borderTRV"></div>

        <div class="borderBRH"></div>
        <div class="borderBRV"></div>

        <div class="borderBLH"></div>
        <div class="borderBLV"></div>

        <div class="inner">
            <div class="item right bottom">
                <img src="image/stocznia/01.jpg" class="img" alt="Stocznia 01" title="Stocznia 01">
            </div>
            <div class="hr-vertical hr-vertical-top-small"></div>
            <div class="item center bottom">
                <img src="image/stocznia/02.jpg" class="img" alt="Stocznia 02" title="Stocznia 02">
            </div>
            <div class="hr-vertical hr-vertical-top-small"></div>
            <div class="item left bottom">
                <img src="image/stocznia/03.jpg" class="img" alt="Stocznia 03" title="Stocznia 03">
            </div>

            <div class="column-3 hr-horizontal"></div>

            <div class="item column-3 center middle">
                <div>
                    
                    <p class="font-large text-center">Stocznia. Drugie życie</p>
                    <p class="font-medium  text-center">
                        II nagroda - kategoria PRZEMYSŁ OKRĘTOWY DZISIAJ<br>
                        III nagroda - kategoria MŁODE MIASTO #nowehistorie<br>
                        wyróżnienie I - kategoria OPEN
                    </p>
                </div>
            </div>

            <div class="column-3 hr-horizontal"></div>

            <div class="item right top">
                <p class="text text-center sm">Opowieść o zmianie i odrodzeniu przyniosła mi II nagrodę w konkursie "Stocznia. Drugie życie". Moje zdjęcia ukazują, jak dawniej przemysłowe tereny stoczniowe zyskały nowe życie i energię. Fotografie łączą historię z nowoczesnym wykorzystaniem tych przestrzeni, pokazując ich transformację i współczesną dynamikę.</p>
            </div>
            <div class="hr-vertical hr-vertical-bottom-small"></div>
            <div class="item center top">
                <p class="text text-center lg">Opowieść o zmianie i odrodzeniu przyniosła mi II nagrodę w konkursie "Stocznia. Drugie życie". Moje zdjęcia ukazują, jak dawniej przemysłowe tereny stoczniowe zyskały nowe życie i energię. Fotografie łączą historię z nowoczesnym wykorzystaniem tych przestrzeni, pokazując ich transformację i współczesną dynamikę.</p>
            </div>
            <div class="hr-vertical hr-vertical-bottom-small"></div>
            <div class="item left top"></div>
        </div>
    </div>
</div>

<!-- section 4 -->
<div class="container">
    <div class="outer">
        <div class="borderTLH"></div>
        <div class="borderTLV"></div>

        <div class="borderTRH"></div>
        <div class="borderTRV"></div>

        <div class="borderBRH"></div>
        <div class="borderBRV"></div>

        <div class="borderBLH"></div>
        <div class="borderBLV"></div>

        <div class="inner">
            <div class="item right bottom">
                <img src="image/gallery/01.jpg" class="img" alt="Galeria 01" title="Galeria 01">
            </div>
            <div class="hr-vertical hr-vertical-top-small"></div>
            <div class="item center bottom">
                <img src="image/gallery/02.jpg" class="img" alt="Galeria 01" title="Galeria 02">
            </div>
            <div class="hr-vertical hr-vertical-top-small"></div>
            <div class="item left bottom">
                <img src="image/gallery/03.jpg" class="img" alt="Galeria 03" title="Galeria 03">
            </div>

            <div class="column-3 hr-horizontal"></div>

            <div class="item right middle">
                <p class="text text-center">Każde zdjęcie opowiada unikalną historię i skłania do refleksji nad otaczającym nas światem…</p>
            </div>
            <div class="hr-vertical hr-vertical-middle"></div>
            <div class="item center middle">
                <p class="font-large text-center">Galeria</p>
            </div>
            <div class="hr-vertical hr-vertical-middle"></div>
            <div class="item left middle">
                <img src="image/gallery/04.jpg" class="img" alt="Galeria 04" title="Galeria 04">
            </div>

            <div class="column-3 hr-horizontal"></div>

            <div class="item right top">
                <img src="image/gallery/07.jpg" class="img" alt="Galeria 07" title="Galeria 07">
            </div>
            <div class="hr-vertical hr-vertical-bottom-small"></div>
            <div class="item center top">
                <img src="image/gallery/06.jpg" class="img" alt="Galeria 06" title="Galeria 06">
            </div>
            <div class="hr-vertical hr-vertical-bottom-small"></div>
            <div class="item left top">
                <img src="image/gallery/05.jpg" class="img" alt="Galeria 05" title="Galeria 05">
            </div>
        </div>
    </div>
</div>
</div>
</body>
</html>
`;

mediumZoom('img')